import React, { useEffect } from "react"
import Box from "@material-ui/core/Box"
// import { useAuth } from "use-auth0-hooks"
import useJobs from "./hooks/use-jobs.js"
import * as colors from "@material-ui/core/colors"
import SectionTitle from "./SectionTitle.js"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { styled } from "@material-ui/core/styles"
import moment from "moment"

const usdFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const StyledButton = styled(Button)({
  margin: 4,
})

const StyledHref = styled("a")({
  color: colors.blue[500],
  textDecoration: "none",
})

const Money = styled("div")({ fontVariantNumeric: "tabular-nums" })

const JobRow = ({ custom_id, status, created_at, price }) => {
  return (
    <TableRow>
      <TableCell>
        <StyledHref href={custom_id}>{custom_id}</StyledHref>
      </TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        <Money>{usdFormatter.format(price)}</Money>
      </TableCell>
      <TableCell>{moment(created_at).fromNow()}</TableCell>
      <TableCell>
        <Box textAlign="center">
          {(status === "in-progress" || status === "pending") && (
            <StyledButton className="red" variant="outlined">
              Stop Early
            </StyledButton>
          )}
          {status === "complete" && (
            <StyledButton variant="outlined">Delete</StyledButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default () => {
  let {
    isLoading,
    isAuthenticated,
    jobs = [],
    deleteJob,
    stopJobEarly,
  } = useJobs()
  // if (isLoading) return <div>...</div>
  // if (!isAuthenticated) return null
  return (
    <div>
      <Box marginTop={12} display="flex" justifyContent="center">
        <Box width="100%" maxWidth={1200}>
          <Box textAlign="center">
            <SectionTitle>Your Datasets</SectionTitle>
          </Box>
          {jobs.length === 0 ? (
            <Box textAlign="center" justifyContent="center">
              <Box
                fontSize={18}
                color={colors.grey[600]}
                paddingTop={4}
                paddingBottom={4}
              >
                No datasets labeled yet.
              </Box>
            </Box>
          ) : (
            <TableContainer
              style={{ border: `1px solid ${colors.grey[300]}` }}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Link</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((job) => (
                    <JobRow key={job.custom_id} {...job} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </div>
  )
}
