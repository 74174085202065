import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
})

export default ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)
