import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react"
import useTeam from "./use-team.js"
import { useAuth } from "use-auth0-hooks"
import { apiEndpoint } from "../config"

const PaymentMethodContext = createContext()

export const PaymentMethodProvider = ({ children }) => {
  const { team, setTeamDirty } = useTeam()
  const { token } = useAuth(true)
  const [dirtyPaymentMethods, setDirtyPaymentMethods] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    if (!team || !token) {
      if (paymentMethods) setPaymentMethods([])
      return
    }
    async function loadPaymentMethods() {
      const paymentMethods = await fetch(
        `${apiEndpoint}/payment_method?select=*,last_used&team_id=eq.${team.team_id}`,
        {
          headers: { Authorization: `Bearer ${token.idToken}` },
        }
      ).then((r) => r.json())
      setPaymentMethods(paymentMethods)
      if (dirtyPaymentMethods) setDirtyPaymentMethods(false)
    }
    loadPaymentMethods()
  }, [team, dirtyPaymentMethods])

  const contextValue = useMemo(
    () => ({
      paymentMethods,
      addPaymentMethod: async ({ isDefault, nickname, stripeDetails }) => {
        await fetch(`${apiEndpoint}/payment_method`, {
          method: "POST",
          body: JSON.stringify({
            is_default: isDefault,
            nickname,
            stripe_details: stripeDetails,
            team_id: team.team_id,
          }),
          headers: {
            Authorization: `Bearer ${token.idToken}`,
            "Content-Type": "application/json",
          },
        })
        setDirtyPaymentMethods(true)
      },
      depositFunds: async ({
        paymentMethodId,
        purpose = "Universal Data Tool Label Help Credit Deposit",
        amount,
      }) => {
        await fetch(`${apiEndpoint}/deposit`, {
          method: "POST",
          body: JSON.stringify({
            payment_method_id: paymentMethodId,
            purpose,
            amount,
            team_id: team.team_id,
          }),
          headers: {
            Authorization: `Bearer ${token.idToken}`,
            "Content-Type": "application/json",
          },
        })
        setTeamDirty(true)
      },
    }),
    [paymentMethods]
  )
  return (
    <PaymentMethodContext.Provider value={contextValue}>
      {children}
    </PaymentMethodContext.Provider>
  )
}

export default () => useContext(PaymentMethodContext)
