import React from "react"
import { styled } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"

export const SectionTitle = styled("div")({
  display: "inline-flex",
  fontSize: 36,
  fontWeight: 800,
  marginTop: 64,
  marginBottom: 32,
  borderBottom: `4px solid ${colors.blue[500]}`,
})

export default SectionTitle
