import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import * as colors from "@material-ui/core/colors"
import { styled } from "@material-ui/core/styles"
import { useAuth } from "use-auth0-hooks"

const Link = styled("a")({
  textDecoration: "none",
  color: colors.blue[500],
})

const TitleText = styled("div")({
  marginTop: 48,
  fontSize: 48,
  fontWeight: 800,
})
const TitleEmp = styled("span")({
  borderBottom: `4px solid ${colors.blue[500]}`,
})
const SubtitleText = styled("div")({
  fontSize: 18,
  marginTop: 48,
  lineHeight: 1.5,
})

const StyledButton = styled(Button)({
  margin: 8,
})

export default () => {
  const { isLoading, isAuthenticated, login, logout } = useAuth()
  return (
    <Box marginTop={8} display="flex" justifyContent="center">
      <Box width="100%" maxWidth={1200}>
        <Grid container>
          <Grid item xs={6}>
            <TitleText>
              Label UDT Datasets
              <div>
                <TitleEmp>with Humans</TitleEmp>
              </div>
            </TitleText>
            <SubtitleText>
              UDT Label Help is a fully automated web service for adding
              accurate human annotations to{" "}
              <Link href="https://universaldatatool.com">
                Universal Data Tool
              </Link>{" "}
              datasets.
              <br />
              <br />
              Just upload and watch the work complete.
              {!isLoading && !isAuthenticated && (
                <Box display="flex" paddingTop={3}>
                  <StyledButton
                    color="primary"
                    onClick={() => login()}
                    variant="contained"
                  >
                    Sign Up
                  </StyledButton>
                  <StyledButton
                    color="primary"
                    onClick={() => login()}
                    variant="contained"
                  >
                    Log In
                  </StyledButton>
                </Box>
              )}
              {!isLoading && isAuthenticated && (
                <Box paddingTop={3}>
                  <StyledButton
                    color="primary"
                    variant="contained"
                    onClick={() => logout()}
                  >
                    Log Out
                  </StyledButton>
                </Box>
              )}
            </SubtitleText>
          </Grid>
          <Grid item xs={6}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Box
                width={400}
                height={400}
                style={{ backgroundColor: colors.grey[400] }}
              ></Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
