import React from "react"
import HomePage from "./HomePage"
import Theme from "./Theme"
import { Auth0Provider } from "use-auth0-hooks"
import authConfig from "./auth-config"
import { StripeProvider } from "react-stripe-elements"
import { TeamProvider } from "./hooks/use-team.js"
import { PaymentMethodProvider } from "./hooks/use-payment-methods.js"

function App() {
  return (
    <StripeProvider
      apiKey={
        window.location.origin.includes("localhost")
          ? "pk_test_pd8eejBsFvkhIXTm5NnQ2X5o"
          : "pk_live_b3o3Yv4TaIhSsHi0AQRRxLfy"
      }
    >
      <Auth0Provider {...authConfig}>
        <Theme>
          <TeamProvider>
            <PaymentMethodProvider>
              <HomePage />
            </PaymentMethodProvider>
          </TeamProvider>
        </Theme>
      </Auth0Provider>
    </StripeProvider>
  )
}

export default App
