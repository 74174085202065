import { useEffect, useState, useMemo } from "react"
import useTeam from "./use-team"
import { useAuth } from "use-auth0-hooks"
import { apiEndpoint } from "../config"
import useEventCallback from "use-event-callback"

export default () => {
  const { token } = useAuth(true)
  const { isLoading, isAuthenticated, team } = useTeam()
  const [jobs, setJobs] = useState()
  const [project, setProject] = useState()
  const [jobsDirty, setJobsDirty] = useState(false)
  useEffect(() => {
    if (isLoading || !isAuthenticated || !team || !token) return
    if (jobs && !jobsDirty) return
    async function loadJobs() {
      const projects = await fetch(
        `${apiEndpoint}/project?team_id=eq.${team.team_id}`,
        {
          headers: { Authorization: `Bearer ${token.idToken}` },
        }
      ).then((r) => r.json())
      const udtProject = projects.find(
        (p) => p.project_name === "Universal Data Tool Label Help"
      )
      if (!udtProject) {
        await fetch(`${apiEndpoint}/project?team_id=${team.team_id}`, {
          method: "POST",
          body: JSON.stringify({
            project_name: "Universal Data Tool Label Help",
            team_id: team.team_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.idToken}`,
          },
        })
        return loadJobs()
      }
      setProject(udtProject)
      const jobs = await fetch(
        `${apiEndpoint}/job?project_id=eq.${udtProject.project_id}`,
        {
          headers: { Authorization: `Bearer ${token.idToken}` },
        }
      ).then((r) => r.json())
      setJobs(jobs)
      setJobsDirty(false)
    }
    loadJobs()
  }, [isLoading, isAuthenticated, token, team, jobsDirty])

  const stopJobEarly = useEventCallback(async (job) => {
    if (!job.job_id) throw new Error(`Missing job id`)
    await fetch(`${apiEndpoint}/job?job_id=eq.${job.job_id}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token.idToken}` },
    }).then((r) => r.json())
    setJobsDirty(true)
  })
  const deleteJob = useEventCallback(async (job) => {
    if (!job.job_id) throw new Error(`Missing job id`)
    await fetch(`${apiEndpoint}/job?job_id=eq.${job.job_id}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token.idToken}` },
    }).then((r) => r.json())
    setJobsDirty(true)
  })

  return useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      jobs,
      project,
      stopJobEarly,
      deleteJob,
    }),
    [isAuthenticated, isLoading, jobs, project, stopJobEarly, deleteJob]
  )
}
