import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import * as colors from "@material-ui/core/colors"
import { styled } from "@material-ui/core/styles"
import { GoMarkGithub } from "react-icons/go"

const WaoLink = styled("a")({
  textDecoration: "none",
  color: colors.blue[500],
})

const NavBarButton = styled(Button)({
  marginRight: 8,
  "&:last-child": {
    marginRight: 0,
  },
})

export default () => {
  return (
    <Box justifyContent="center" display="flex">
      <Box
        width="100%"
        maxWidth={1200}
        display="flex"
        alignItems="center"
        padding={2}
      >
        <Box flexGrow={1}>
          <Box display="inline-flex" flexDirection="column">
            <Box
              style={{
                fontWeight: 700,
                backgroundColor: colors.blue[800],
                color: "#fff",
                padding: 4,
                paddingLeft: 12,
                paddingRight: 12,
              }}
              fontSize={36}
            >
              Label Help
            </Box>
            <Box
              fontSize={14}
              fontWeight={600}
              textAlign="right"
              marginTop="4px"
              color={colors.grey[600]}
            >
              powered by <WaoLink href="https://wao.ai">wao.ai</WaoLink>
            </Box>
          </Box>
        </Box>
        <Box>
          <NavBarButton>Home</NavBarButton>
          <NavBarButton>Pricing</NavBarButton>
          {/* <NavBarButton>Ethical Outsourcing</NavBarButton> */}
          <NavBarButton>
            <GoMarkGithub style={{ marginRight: 6, fontSize: 18 }} /> Universal
            Data Tool
          </NavBarButton>
        </Box>
      </Box>
    </Box>
  )
}
