import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"

const useStyles = makeStyles({
  container: {
    padding: 40,
    paddingTop: 80,
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "calc(100vw - 40px)",
    maxWidth: 1200,
    "& a": {
      color: colors.grey[700],
      textDecoration: "none",
    },
  },
  col: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  tit: {
    marginTop: 12,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 12,
    marginBottom: 12,
    opacity: 0.8,
  },
  item: {
    display: "block",
    opacity: 0.8,
    marginTop: 4,
    cursor: "pointer",
  },
  address: {
    opacity: 0.8,
  },
  copy: { textAlign: "center", opacity: 0.7, marginTop: 80, paddingBottom: 20 },
})

export default () => {
  const c = useStyles()
  return (
    <Box
      style={{
        borderTop: `solid 4px ${colors.grey[200]}`,
        backgroundColor: colors.grey[100],
      }}
      marginTop={32}
      minHeight={400}
      width="100%"
      textAlign="center"
    >
      <Box width="100%" minWidth={1200} textAlign="left">
        <div className={c.container}>
          <div className={c.colContainer}>
            <div className={c.col}>
              <div className={c.tit}>Links</div>
              {/* <a onClick={auth.login} className={c.item}>
                Sign Up/Sign In
              </a> */}
              <a href="https://universaldatatool.com" className={c.item}>
                Universal Data Tool
              </a>
              <a href="https://wao.ai" className={c.item}>
                wao.ai
              </a>
              <a href="https://app.workaround.online/" className={c.item}>
                Become a Labeler
              </a>
              <a href="https://developer.wao.ai" className={c.item}>
                Use the API
              </a>
            </div>
            <div className={c.col}>
              <div className={c.tit}>Legal</div>
              <a href="https://wao.ai/legal" className={c.item}>
                Terms of Use
              </a>
              <a href="https://wao.ai/legal" className={c.item}>
                Privacy Policy
              </a>
              <a href="https://wao.ai/legal" className={c.item}>
                User Agreement
              </a>
              <a href="https://wao.ai/legal" className={c.item}>
                Cookie Policy
              </a>
            </div>
            <div className={c.col}>
              <div
                style={{ width: 600, color: colors.grey[600], lineHeight: 1.5 }}
              >
                UDT Label Help was built in collaboration with Universal Data
                Tool maintainers and wao.ai (WorkAround Online Inc.) to make the
                process of developing artificial intelligence applications
                easier. Some of the profit from this application is used to
                support the Universal Data Tool and open-source development.
              </div>
            </div>
          </div>
          <div className={c.copy}>
            &copy;{new Date().getFullYear()} WorkAround Online Inc.
          </div>
        </div>
      </Box>
    </Box>
  )
}
