// @flow weak

import React, { useState, useEffect, useRef } from "react"
import { styled } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import * as colors from "@material-ui/core/colors"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import AddPaymentMethodDialog from "./AddPaymentMethodDialog"
import usePaymentMethods from "./hooks/use-payment-methods"

const { grey, blue } = colors

const OtherPaymentMethodButton = styled(Button)({
  color: grey[800],
})

export default ({
  open,
  defaultAmountToAdd,
  onClose,
  onFundsAdded = () => {},
}) => {
  const [amountToAdd, setAmountToAdd] = useState(defaultAmountToAdd)
  const { addPaymentMethod, paymentMethods, depositFunds } = usePaymentMethods()
  const [paymentMethodMenuOpen, setPaymentMethodMenuOpen] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
  const changeButtonRef = useRef()

  useEffect(() => {
    if (!selectedPaymentMethod && paymentMethods) {
      setSelectedPaymentMethod(
        (paymentMethods || []).find((pm) => pm.is_default)
      )
    }
  }, [selectedPaymentMethod, paymentMethods])

  const [pending, changePending] = useState(false)

  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] = useState(
    false
  )

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            Add Funds
            <div style={{ flexGrow: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box minWidth={400}>
            <Box color={grey[600]}>Payment Method</Box>
            {selectedPaymentMethod ? (
              <Box
                display="flex"
                marginTop={2}
                alignItems="center"
                color={grey[800]}
                padding={2}
                border={`1px solid ${grey[300]}`}
                borderRadius={5}
              >
                <CreditCardIcon style={{ color: grey[800], marginRight: 8 }} />
                <Box flexGrow={1}>{selectedPaymentMethod.nickname}</Box>
                <OtherPaymentMethodButton
                  ref={changeButtonRef}
                  onClick={() => {
                    setPaymentMethodMenuOpen(true)
                  }}
                >
                  Change
                </OtherPaymentMethodButton>
                <Menu
                  anchorEl={changeButtonRef.current}
                  open={paymentMethodMenuOpen}
                  onClose={() => setPaymentMethodMenuOpen(false)}
                >
                  {paymentMethods.map((pm) => (
                    <MenuItem
                      onClick={() => {
                        setSelectedPaymentMethod(pm)
                        setPaymentMethodMenuOpen(false)
                      }}
                      key={pm.payment_method_id}
                    >
                      <CreditCardIcon
                        style={{ color: grey[800], marginRight: 8 }}
                      />
                      <Box>{pm.nickname}</Box>
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={() => {
                      setPaymentMethodMenuOpen(false)
                      setAddPaymentMethodDialogOpen(true)
                    }}
                    key="create new"
                  >
                    Add New Payment Method
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setAddPaymentMethodDialogOpen(true)
                }}
                style={{
                  color: blue[600],
                  textAlign: "center",
                  border: `1px solid ${blue[300]}`,
                  marginTop: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                Add Payment Method
              </Button>
            )}
            <Box marginTop={3}>
              <TextField
                fullWidth
                label="Amount To Add"
                variant="outlined"
                onChange={(e) => {
                  setAmountToAdd(parseFloat(e.target.value))
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box lineHeight={1.5} fontSize={12} padding={2} color={grey[600]}>
              The payment method above will be charged after clicking "Add
              Funds". The credits will deposit into your account instantly. If
              you have any questions, email{" "}
              <a style={{ color: blue[500] }} href="mailto:support@wao.ai">
                support@wao.ai
              </a>
              . For advanced settings, sign in on{" "}
              <a style={{ color: blue[500] }} href="https://wao.ai">
                wao.ai
              </a>
              .<br />
              <br />
              All payment information is securely handled by Stripe.
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>
            <span style={{ color: grey[700], fontWeight: 500 }}>Cancel</span>
          </Button>
          {!pending ? (
            <Button
              style={{ color: blue[500], fontWeight: 500 }}
              onClick={async () => {
                changePending(true)
                await depositFunds({
                  paymentMethodId: selectedPaymentMethod.payment_method_id,
                  amount: amountToAdd,
                })
                changePending(false)
                onClose()
                onFundsAdded()
              }}
            >
              Add Funds
            </Button>
          ) : (
            <CircularProgress size={24} />
          )}
        </DialogActions>
      </Dialog>
      <AddPaymentMethodDialog
        open={addPaymentMethodDialogOpen}
        onClose={() => {
          setAddPaymentMethodDialogOpen(false)
        }}
        onAdd={() => {
          setAddPaymentMethodDialogOpen(false)
        }}
      />
    </>
  )
}
