// @flow weak

import { useAuth } from "use-auth0-hooks"
import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react"
import { apiEndpoint } from "../config"

const apiKeyPurpose = "Universal Data Tool Label Help"

const TeamContext = createContext()

export const TeamProvider = ({ children }) => {
  const { isLoading, isAuthenticated, token, user, logout } = useAuth(true)
  const [team, changeTeam] = useState()
  const [teamDirty, setTeamDirty] = useState(false)
  useEffect(() => {
    if (isLoading || !isAuthenticated || !token) return
    async function loadTeam() {
      const teams = await fetch(
        `${apiEndpoint}/team?select=credit,team_id,api_keys&team_name=eq.${user.nickname}`,
        {
          headers: { Authorization: `Bearer ${token.idToken}` },
        }
      ).then((r) => r.json())
      if (teams.length === 0) {
        // Need to initialize team
        await fetch(`${apiEndpoint}/me`, {
          method: "POST",
          body: "{}",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.idToken}`,
          },
        })
        return loadTeam()
      }

      const team = teams[0]
      team.apiKey = team.api_keys.find(
        (apiKey) => apiKey.purpose === apiKeyPurpose
      )
      if (team.apiKey) team.apiKey = team.apiKey.key_string
      if (!team.apiKey) {
        await fetch(`${apiEndpoint}/api_key`, {
          method: "POST",
          body: JSON.stringify({
            purpose: apiKeyPurpose,
            team_id: team.team_id,
            production: true,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.idToken}`,
          },
        })
        team.apiKey = (
          await fetch(
            `${apiEndpoint}/api_key?team_id=eq.${
              team.team_id
            }&purpose=eq.${encodeURIComponent(apiKeyPurpose)}`,
            {
              headers: { Authorization: `Bearer ${token.idToken}` },
            }
          )
        )[0].key_string
      }
      setTeamDirty(false)
      changeTeam(team)
    }
    loadTeam()
  }, [isLoading, isAuthenticated, token, teamDirty])

  const contextValue = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      team,
      setTeamDirty,
    }),
    [isLoading, team, isAuthenticated, setTeamDirty]
  )

  return (
    <TeamContext.Provider value={contextValue}>{children}</TeamContext.Provider>
  )
}

export default () => {
  return useContext(TeamContext)
}
