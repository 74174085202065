// @flow weak

import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import { grey, blue } from "@material-ui/core/colors"
import { Elements, CardElement, injectStripe } from "react-stripe-elements"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Checkbox from "@material-ui/core/Checkbox"
import usePaymentMethods from "./hooks/use-payment-methods"

const cardElementStyle = {
  base: {
    fontSize: "14px",
    color: "#424770",
    fontFamily: '"Inter", sans-serif',
    letterSpacing: "0.025em",
    "::placeholder": {
      color: grey[600],
    },
  },
  invalid: {
    color: "#c23d4b",
  },
}

const CheckoutElement = () => {
  return <CardElement style={cardElementStyle} />
}

const MainElement = injectStripe(({ open, onClose, onAdd, stripe }) => {
  const [state, changeState] = useState({ isDefault: true })
  const { paymentMethods, addPaymentMethod } = usePaymentMethods()
  const [pending, changePending] = useState(false)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Add a Payment Method
          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          changePending(true)
          try {
            const stripeResult = await stripe.createPaymentMethod("card")
            const { paymentMethod } = stripeResult
            if (!paymentMethod) throw new Error("Payment method not accepted")
            // console.log("Received Stripe PaymentMethod:", paymentMethod)
            await addPaymentMethod({
              nickname: state.nickname,
              isDefault: state.isDefault,
              stripeDetails: paymentMethod,
            })
            onAdd()
          } catch (e) {
            changePending(false)
          }
        }}
      >
        <DialogContent>
          <Box minWidth={400}>
            <Box paddingBottom={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nickname for Card"
                onChange={(e) => {
                  changeState({ ...state, nickname: e.target.value })
                }}
              />
            </Box>
            <Box padding={2} border="1px solid #ccc" borderRadius={4}>
              <CheckoutElement />
            </Box>
            <Box
              paddingTop={2}
              alignItems="center"
              color={grey[600]}
              display="flex"
            >
              <Checkbox
                checked={state.isDefault}
                onClick={(e, checked) => {
                  changeState({ ...state, isDefault: checked })
                }}
              />
              Make this my default payment method
            </Box>
            <Box fontSize={12} padding={2} color={grey[600]}>
              All payment information is stored securely by Stripe.
              <br />
              <br />
              UDT Label Help is provided by{" "}
              <a
                style={{ color: blue[500], textDecoration: "none" }}
                href="https://wao.ai"
              >
                wao.ai
              </a>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>
            <span style={{ color: grey[700], fontWeight: 500 }}>Cancel</span>
          </Button>
          {!pending ? (
            <Button style={{ color: blue[500], fontWeight: 500 }} type="submit">
              Add Card
            </Button>
          ) : (
            <CircularProgress size={24} />
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default (props) => {
  return (
    <Elements>
      <MainElement {...props} />
    </Elements>
  )
}
