const isLocal =
  typeof window !== "undefined" && window.location.href.includes("localhost")
const authConfig = isLocal
  ? {
      domain: "workaroundonline.auth0.com",
      clientId: "MRDdao175UckM4CGqhEFMGFa3Qy3T5C4",
      callbackUrl: window.location.origin,
      redirectUri: window.location.origin,
    }
  : {
      domain: "workaroundonline.auth0.com",
      clientId: "yEVqwQF3O7dDUlLlaLSuuA5avyjYYeDi",
      callbackUrl: window.location.origin,
      redirectUri: window.location.origin,
    }

export const audienceScope = {
  audience: "yEVqwQF3O7dDUlLlaLSuuA5avyjYYeDi",
  scope: "openid profile",
}

export default authConfig
