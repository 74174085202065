import React, { useEffect, useState } from "react"
import { styled } from "@material-ui/core/styles"
import useTeam from "./hooks/use-team.js"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import AddFundsDialog from "./AddFundsDialog"
import * as colors from "@material-ui/core/colors"
import AddFundsIcon from "@material-ui/icons/ArrowUpward"
import SectionTitle from "./SectionTitle"

const APIKeyBox = styled("div")({
  display: "inline-block",
  fontWeight: 800,
  fontSize: 24,
  padding: 16,
  marginTop: 36,
  backgroundColor: colors.grey[200],
  minWidth: 400,
  textAlign: "center",
})

const APIKeyInstructions = styled("div")({
  marginTop: 24,
  fontStyle: "italic",
  color: colors.grey[700],
})

const BalanceContainer = styled("div")({
  display: "inline-block",
  marginTop: 32,
  backgroundColor: "#fff",
  boxShadow: "0px 3px 5px rgba(0,0,0,0.1)",
  border: `1px solid ${colors.grey[400]}`,
  borderRadius: 2,
  padding: 16,
  position: "relative",
})
const BalanceTitle = styled("div")({
  textAlign: "left",
  marginBottom: 8,
  fontSize: 12,
  fontWeight: 600,
  textTransform: "uppercase",
  color: colors.grey[600],
})
const Balance = styled("div")({
  fontSize: 36,
  fontWeight: 800,
})
const AddFundsButton = styled(IconButton)({
  position: "absolute",
  right: -32,
  top: 8,
  backgroundColor: colors.green[600],
  color: "#fff",
  transition: "transform 150ms",
  "&:hover": {
    backgroundColor: colors.green[800],
    transform: "scale(1.05, 1.05)",
  },
  "& .icon": {},
})

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
})

export default ({}) => {
  const [addFundsDialogOpen, setAddFundsDialogOpen] = useState(false)
  const { isLoading, isAuthenticated, team } = useTeam()
  // if (isLoading || !team) return <div>...</div>
  // if (!isAuthenticated) return <div>login to see api settings</div>
  return (
    <Box marginTop={20} display="flex" justifyContent="center">
      <Box maxWidth={1200} width="100%">
        <Box textAlign="center">
          <SectionTitle>Your API Key</SectionTitle>
          <div>
            <APIKeyBox style={!team ? { color: colors.grey[600] } : {}}>
              {isLoading
                ? "loading..."
                : team
                ? team.apiKey
                : "log in to get API key"}
            </APIKeyBox>
          </div>
          <div>
            <APIKeyInstructions>
              Paste this into the dialog in the Universal Data Tool
            </APIKeyInstructions>
          </div>
          {team && (
            <div>
              <BalanceContainer>
                <BalanceTitle>Balance</BalanceTitle>
                <Balance>{moneyFormatter.format(team.credit)}</Balance>
                <AddFundsButton onClick={() => setAddFundsDialogOpen(true)}>
                  <AddFundsIcon className="icon" />
                </AddFundsButton>
              </BalanceContainer>
            </div>
          )}
        </Box>
        <AddFundsDialog
          onClose={() => setAddFundsDialogOpen(false)}
          open={addFundsDialogOpen}
        />
      </Box>
    </Box>
  )
}
