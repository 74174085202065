import React from "react"
import Header from "./Header"
import LandingHero from "./LandingHero.js"
import LoginButton from "./LoginButton"
import APISettings from "./APISettings"
import JobsList from "./JobsList"
import PageContainer from "./PageContainer"
import Footer from "./Footer"
import bg from "./bg.svg"

export default () => {
  return (
    <PageContainer>
      <img
        style={{
          position: "absolute",
          zIndex: -1,
          left: 0,
          top: 0,
          width: "100%",
          opacity: 0.75,
        }}
        src={bg}
      />
      <Header />
      <LandingHero />
      <APISettings />
      <JobsList />
      <Footer />
    </PageContainer>
  )
}
